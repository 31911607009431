import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth.guard';
import { AppComponent } from './app.component';
import { HeaderModule } from '@coreui/angular';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
    // Declarations for components, directives, and pipes
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
    ],
    // Root component for bootstrapping
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HeaderModule,
    ],
    providers: [
        //AuthGuard,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
