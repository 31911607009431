<div class="jumbotron">
    <h1>
        <img src="assets/images/greenleafrecycle.png" />KEEP IT GREEN 4 U
    </h1>
    <p class="lead">LANDSCAPE MAINTENANCE</p>
    <p class="lead">NO JOB IS TOO BIG OR TOO SMALL, WE DO IT ALL</p>
    <p class="lead">CALL TODAY FOR YOUR FREE ESTIMATE</p>
    <h1>
        <a href="tel://1 (503) 432-6388">(503) 432-6388</a>
    </h1>
</div>

<div class="row">
    <div class="col-md-4">
        <h2>FREE ESTIMATES</h2>
    </div>
    <div class="col-md-4">
        <h2>SENIOR DISCOUNTS</h2>
    </div>
    <div class="col-md-4">
        <h2>AFFORDABLE - <br />QUALITY SERVICE</h2>
    </div>
</div>

<div class="row">
    <div class="col-md-4">
        <h2>MOWING</h2>
    </div>
    <div class="col-md-4">
        <h2>THATCHING</h2>
    </div>
    <div class="col-md-4">
        <h2>AERATING</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <h2>EDGING</h2>
    </div>
    <div class="col-md-4">
        <h2>TRIMMING</h2>
    </div>
    <div class="col-md-4">
        <h2>PRUNING</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <h2>WEEDING</h2>
    </div>
    <div class="col-md-4">
        <h2>FERTILIZING</h2>
    </div>
    <div class="col-md-4">
        <h2>CLEANUPS</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <h2>BARKDUST</h2>
    </div>
    <div class="col-md-4">
        <h2>MOSS CONTROL</h2>
    </div>
    <div class="col-md-4">
        <h2>PRESSURE WASHING</h2>
    </div>
</div>