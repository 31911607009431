{
  "name": "KeepItGreen4U",
  "version": "2.2.5",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "serve:prod": "ng serve --configuration=production",
    "prebuild:dev": "npm --no-git-tag-version version patch",
    "build:dev": "ng build --configuration development",
    "prebuild:prod": "npm --no-git-tag-version version patch",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build:stats": "ng build --configuration production --stats-json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/platform-server": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@coreui/angular": "^5.2.13",
    "bootstrap": "^5.3.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.4",
    "@angular/cli": "^18.1.4",
    "@angular/compiler-cli": "^18.1.0",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.2"
  }
}
